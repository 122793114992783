/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import NotFound from './pages/other/notFound';
import CheckoutPage from './pages/checkout';
import SuccessPage from './pages/other/success';
import ErrorPage from './pages/other/error';
import ReceiptPage from './pages/receipt/receipt';
import AllAppeals from './pages/other/allAppeals';
// import HomePage from './pages/home';
import SubCategory from './pages/products/subCategory';
import Products from './pages/products/products';
import RouteContext from './context/route';
// import ZakatCalculator from './pages/zakat/zakatCalculator';
import AliBanatPage from './pages/other/aliBanat';
import FAQsPage from './pages/other/faq';
import PoliciesPage from './pages/other/policies';
import SocialMedia from './pages/other/socialMedia';
import GDPR from './pages/other/gdpr';
import PrivacyPolicyPage from './pages/other/privacyPolicy';
import AnnualReports from './pages/other/annualReports';
import ScrollToTop from './utils/ScrollToTop';
import Contact from './pages/contact';
import Live from './pages/other/Live';
import AllProducts from './pages/allProducts';
import AppPage from './pages/other/app';
import PurposePage from './pages/other/purpose';
import CancelPage from './pages/other/cancel';
import GetReceiptPage from './pages/receipt/getReceipt';
import OptOutPage from './pages/other/opt-out';
import ParticipantPage from './pages/other/participantRegistration';
// import FundraisingPage from './pages/fundraising';
import QurbanMostPopularPage from './pages/qurban/qurban-mostPopular';
import SadaqahMostPopularPage from './pages/qurban/sadaqah-mostPopular';
import SheepsPage from './pages/qurban/sheeps';
import CowsPage from './pages/qurban/cows';
import ZakatLandingPage from './pages/zakat/zakat-landing';
import DashboardPage from './pages/dashboard';
import QuickDonatePage from './pages/other/quick-donate';
import FoodAndWaterLandingPage from './pages/foodAndWater/index';
// import WaterAidLandingPage from './pages/foodAndWater/water-aid';
// import FoodAidLandingPage from './pages/foodAndWater/food-aid';
import LoginPage from './pages/dashboard/pages/login';
import OneBillionLegacyPage from './pages/one-billion';
import MorningTeaPage from './pages/events/morning-tea';
import LiveFundraiserPage from './pages/events/live-fundraiser';
import YasminMogahedIndonesiaPage from './pages/events/yasmin-indonesia';
import YasminMogahedMalaysiaPage from './pages/events/yasmin-malaysia';
import YasminMogahedSingaporePage from './pages/events/yasmin-singapore';
import AsmaaAndBelalPage from './pages/events/asmaa-and-belal';
import VIPPage from './pages/vip';
import NewVolunteerPage from './pages/volunteer';
// import OrphanFeastLandingPage from './pages/orphans/orphan-feast';
import OrphanEducationLandingPage from './pages/orphans/orphan-education';
import OrphanSupportLandingPage from './pages/orphans/orphan-support';
import MoroccoEmergencyLandingPage from './pages/crisis-and-emergencies/morocco-emergency';
import EarthquakeAppealPage from './pages/crisis-and-emergencies/earthquake-appeal';
// import LibyaEmergencyLandingPage from './pages/crisis-and-emergencies/libya-emergency';
import ZakatWidgetEmbedded from './pages/zakat/zakatWidgetEmbedded';
import OrphanHomesLandingPage from './pages/sadaqah-jariyah/orphan-homes';
// import OrphansLandingPage from './pages/orphans';
import YasirandnoumanPage from './pages/events/yasir';
// import BuildWaterWellLandingPage from './pages/sadaqah-jariyah/build-water-well';
import SustainableLandingPage from './pages/sadaqah-jariyah/sustainable';
import OrphanagesLandingPage from './pages/sadaqah-jariyah/orphanages';
// import MasjidsLandingPage from './pages/sadaqah-jariyah/masjids';
import EmergencyLandingPage from './pages/sadaqah-jariyah/emergency';
// import SadaqahJariyahLandingPage from './pages/sadaqah-jariyah';
import YasirandnoumanMalaysiaPage from './pages/events/yasir-malaysia';
import NoumanalikhanLandingPage from './pages/events/noumanalikhan';
import MakeADonationLandingPage from './pages/donate-now/make-a-donation';
// import UKHome from './pages/ukHome';
// import PalestineNewLandingPage from './pages/crisis-and-emergencies/palestineNew';
import PalestineCampaignLandingPage from './pages/crisis-and-emergencies/palestine-campaign';
import NoumanalikhanMalaysiaLandingPage from './pages/events/noumanalikhan-malaysia';
import LoveOnesLandingPage from './pages/loved-ones-legacy';
import UkModal from './components/general/UkModal';
import WinterLandingPage from './pages/winter';
import CallCenter from './pages/call-center';
import PalestineResponseLandingPage from './pages/crisis-and-emergencies/palestine-response';
import EmergencyResponseLandingPage from './pages/crisis-and-emergencies/emergency-response';
import MuftiMenkLandingPage from './pages/events/muftimenk';
// import ProjectNoorLandingPage from './pages/sadaqah-jariyah/project-noor-new';
import DonorReceipts from './pages/receipt/donorReceipts';
import USAPage from './pages/ukHome/usa-home';
// import Events from './pages/events';
import EventDetails from './pages/events/eventDetails';
import YasminmogahedLandingPage from './pages/events/yasminmogahed';
import GlobalPage from './pages/global';
import TestWidget from './pages/test-widget/testWidget';
import USAGeneralDonation from './pages/usa/usaGeneralDonation';
import SubscriptionLandingPage from './pages/subscription/index';
import PalestineArabic from './pages/crisis-and-emergencies/palestineArabic';
import { useSelector } from 'react-redux';
import { selectSelectedCountry } from './store/user';
import { useEffect } from 'react';
import DetailsProduct from './pages/products/details';
// import ZakatPreRamadan from './pages/zakat/zakatPreRamadan';
import FidyaKaffarah from './pages/fidya-kaffarah/fidyaKaffarah';
import SubscriptionOld from './pages/subscription';
import PalestineWeekly from './pages/crisis-and-emergencies/palestine-weekly';
import YasminmogahedAustraliaPage from './pages/events/yasminmogahed-australia';
import UpTimeStatus from './pages/other/upTimeStatus';
import ZakatAlFitr from './pages/zakat/zakatAlFitr';
import MasjidsNewLandingPage from './pages/sadaqah-jariyah/masjidsNew';
import ProjectNoorUAT from './pages/sadaqah-jariyah/projectNoorUAT';
import FidyaKaffarahUat from './pages/fidya-kaffarah/fidyaKaffarahUat';
import FoodAidUATLandingPage from './pages/foodAndWater/food-aid-UAT';
import FundraisingLandingPageUAT from './pages/fundraising/index-uat';
import HealthLandingPage from './pages/sadaqah-jariyah/health';
import OurPromise from './pages/other/ourPromise';
import ZakatCalculatorUATLandingPage from './pages/zakat/zakatCalculatorUAT';
import SadaqahjariahUat from './pages/sadaqah-jariyah/sadaqahJariahUat';
import OrphansUat from './pages/orphans/orphansUat';
import BuildWaterWellUat from './pages/sadaqah-jariyah/buildWaterWellUat';
import OrphansFeastUat from './pages/orphans/orphansFeastUat';
import WaterAidUATLandingPage from './pages/foodAndWater/water-aid-uat';
import VolunteerUATPage from './pages/volunteer/volunteer-uat';
import ZakatUat from './pages/zakat/zakatUat';
import KaffarahLandingPage from './pages/fidya-kaffarah/kaffarah';
import FidyaLandingPage from './pages/fidya-kaffarah/fidya';
import HundredDonationsPolicy from './pages/other/hundredDonationsPolicy';
import Fitrana from './pages/fitrana';
import SentCryptoPage from './pages/crypto/sentCrypto';
import CancelCryptoPage from './pages/crypto/cancelCrypto';
import DonateToPalestine from './pages/crisis-and-emergencies/donateToPalestine';
import RamadanDua from './pages/ramadan/ramadanDua';
import ZakatOnGold from './pages/zakat/zakatOnGold';
import DuaForBreakingFast from './pages/ramadan/duaForBreakingFast';
import SponsorAnOrphan from './pages/orphans/sponsorAnOrphan';
import WhenIsEid from './pages/ramadan/whenIsEid';
import Aqiqah from './pages/sadaqah-jariyah/aqiqah';
import OliveTreeSadaqah from './pages/sadaqah-jariyah/ oliveTreeSadaqah';
import SadaqahjariahForDeceased from './pages/sadaqah-jariyah/SadaqahjariahForDeceased';
import GiftGiving from './pages/giftGiving';
// import PalestineUat from './pages/crisis-and-emergencies/palestineUat';
import SadaqahAndLillah from './pages/sadaqah-jariyah/sadaqahAndLillah';
import DuaForPalestine from './pages/crisis-and-emergencies/duaForPalestine';
import DuaForParents from './pages/sadaqah-jariyah/duaForParents';
import NewPage from './pages/newsPage';
import Nisab from './pages/zakat/nisab';
// import LaylatulQadr from './pages/ramadan/laylatulQadr';
import CampaignTiktok from './pages/other/campaignTiktok';
import RamadanLandingPage from './pages/ramadan';
import HomePageUAT from './pages/home/index-UAT';
// import PalestineUatu from './pages/crisis-and-emergencies/palestine';
import PalestineFamineLandingPage from './pages/crisis-and-emergencies/palestineFamine';
// import Last10NightsLandingPage from './pages/ramadan/last10Nights';
import GiftOfSoundLandingPage from './pages/sadaqah-jariyah/giftOfSound';
import PalestineLandingPage from './pages/crisis-and-emergencies/palestine';
import LaylaTulQadrLandingPage from './pages/ramadan/laylatulQadar';
// import LastTenNightsLandingPage from './pages/ramadan/lastTenNights/lastTenNights';
import PhishingLandingPage from './pages/other/phishing';
import LaylatulQadr2024LandingPage from './pages/ramadan/laylatulQadr2024';
import AdminFeesPage from './pages/other/adminFees';
import SubscriptionABLandingPage from './pages/subscription-ab';
import LiveMeals from './pages/other/live-meals';
import PalestineRefugeeLandingPage from './pages/crisis-and-emergencies/palestineRefugeeSettlement';
import EidAlFitr2024 from './pages/eidAlFitr2024';
import EidMubarak2024 from './pages/eidAlFitr2024/eidMubarak2024';
import SaveOrphansLandingPage from './pages/crisis-and-emergencies/save-orphans';

import TopAppeals from './pages/topAppeals';
import DonorLogin from './pages/donor/pages/login';
import DonorPage from './pages/donor';
import VerifyOTP from './pages/donor/pages/verifyOTP';

import BelalAssadApril from './pages/events/belalassad-april';
import Sadaqah from './pages/sadaqah-jariyah/sadaqah';
// import QurbanLandingPage from './pages/qurban';
import QurbaniLandingPage from './pages/qurban/qurbani';
import QurbanMainLandingPage from './pages/qurban/qurban-main';
import Qurbani2024LandingPage from './pages/qurban/qurbani-2024';
import DonateToGaza from './pages/crisis-and-emergencies/donateToGaza';
import DetailsPalestineProduct from './pages/products/detailsPalestine';
import DhulHijjah2024LandingPage from './pages/qurban/dhul-hijjah-2024';
import EidAlAdha2024LandingPage from './pages/qurban/eid-al-adha-2024';
import KurbanBayramiLandingPage from './pages/qurban/kurban-bayrami-2024';
import AfghanistanLandingPageUAT from './pages/crisis-and-emergencies/afghanistan-uat';
import CheapestQurbani2024LandingPage from './pages/qurban/cheapest-qurbani-2024';
import WidgetEmbedded from './pages/other/embedWidget/widgetEmbedded';
import PalestineAiaPartnershipLandingPage from './pages/crisis-and-emergencies/palestine-aia-partnership';
import MajedMahmoudLandingPage from './pages/events/majedMahmoud';
import QurbanPalestineLandingPage from './pages/qurban/qurban-palestine';
import PalestineArabicLandingPage from './pages/crisis-and-emergencies/palestine-ar';
import UltimateQurbanLandingPage from './pages/qurban/qurban-ultimate';
import OrphansAiaSponsorshipLandingPage from './pages/crisis-and-emergencies/orphans-aia-sponsorshop';
import QurbanDhulHijjahLandingPage from './pages/qurban/qurban-dhul-hijjah';
import QurbanDayOfArafatLandingPage from './pages/qurban/qurban-day-of-arafat';
import DoubleTheDonationPage from './pages/double-the-donation';
import QurbaniDonationLandingPage from './pages/qurban/qurbani-donation';
import PalastineMedicalRelief from './pages/medicalforPalestine/palestine-medical';
import Top10Products from './pages/other/top10Products';
// import USACanadaLandingPage from './pages/events/aliBanatUsa';
import QurbanEidAlAdhaLandingPage from './pages/qurban/qurban-eid-al-adha';
import AppealLandingPage from './pages/appeal';
import AliBanatUSALandingPage from './pages/events/aliBanatUsa';
import AliBanatCADLandingPage from './pages/events/aliBanatCad';
import MuharramLandingPage from './pages/muharram';
import MATWAppealDetailsLandingPage from './pages/MATWAppeal/details';
import MATWAppealLandingPage from './pages/MATWAppeal';
import EventLandingPage from './pages/events/eventPage';
import ChildrenOfGaza from './pages/childrenOfGaza';
import BangladeshAppeal from './pages/crisis-and-emergencies/bangladesh-appeal';
import CountriesInCrisisLandingPage from './pages/crisis-and-emergencies/countriesInCrisis';
import LimbsOfHopeLandingPage from './pages/other/limbsOfHope';
// import FoodAndWaterUATLandingPage from './pages/foodAndWater';

let startTime = null; // Variable to store the start time

const AllRoutes = () => {
  /* ----------------------------- Start variables ---------------------------- */
  const [routeState, setRouteState] = useState([{ name: 'MATW Home', id: '/' }]);
  const [showModal, setShowModal] = useState(false);
  const isAlreadyShown = JSON.parse(localStorage.getItem('isModalShown')) || false;
  const country = useSelector(selectSelectedCountry);
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */
  const closeModal = () => {
    setShowModal(false);
    localStorage.setItem('isModalShown', 'true');
  };
  const checkDayAndShowDialog = () => {
    if (window.location.hostname === 'matwproject.org.uk') {
      return true;
    } else {
      return false;
    }
  };
  // Function to track scrolling and calculate scroll distance
  const trackScroll = () => {
    const today = new Date();
    const dayOfWeek = today.getDay(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday
    if (dayOfWeek !== 5) localStorage.setItem('isModalShown', 'false');
    if (dayOfWeek === 5 && checkDayAndShowDialog() && country === 'uk') {
      const currentTime = Date.now();
      const elapsedTime = currentTime - startTime;
      const mints = new Date(elapsedTime).getMinutes();
      const secs = new Date(elapsedTime).getSeconds();
      if (secs > 20 || mints > 0) {
        setShowModal(true);
        window.removeEventListener('scroll', trackScroll);
      }
    } else {
      window.removeEventListener('scroll', trackScroll);
    }
  };
  /* -------------------------------------------------------------------------- */

  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    startTime = Date.now();
    checkDayAndShowDialog(); //only for Uk domain
    window.addEventListener('scroll', trackScroll);
    return () => {
      window.removeEventListener('scroll', trackScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country]);
  /* -------------------------------------------------------------------------- */

  const getHomePage = () => {
    let component = '';
    switch (window.location.hostname) {
      case 'matwproject.org.uk':
        // component = <UKHome />;
        component = <HomePageUAT />;
        break;
      case 'matwprojectusa.org':
        // component = <USAPage country={"usa"}/>;
        component = <HomePageUAT />;
        break;
      case 'localhost':
        // component = <UKHome />;
        // component =<USAPage country={"usa"}/>;
        component = <HomePageUAT />;
        break;
      default:
        component = <HomePageUAT />;
        break;
    }
    return component;
  };

  return (
    <RouteContext.Provider value={{ routeState, setRouteState }}>
      {showModal && !isAlreadyShown && <UkModal closeModal={closeModal} />}
      <Routes>
        <Route path="/" element={getHomePage()} />
        <Route path="/:lang?/" element={getHomePage()} />
        <Route path="/:lang?/login" element={<LoginPage />} />
        <Route path="/:lang?/donor/login" element={<DonorLogin />} />
        <Route path="/:lang?/donor/verify-otp" element={<VerifyOTP />} />
        <Route path="/:lang?/usa-general-donation" element={<USAGeneralDonation />} />
        <Route path="/:lang?/checkout" element={<CheckoutPage />} />
        <Route path="/:lang?/all-products" element={<AllProducts />} />
        <Route path="/:lang?/all-appeals" element={<AllAppeals />} />
        <Route path="/:lang?/participant-registration" element={<ParticipantPage />} />
        <Route path="/:lang?/fundraising" element={<FundraisingLandingPageUAT />} />
        <Route path="/:lang?/sub-categories" element={<SubCategory />} />
        <Route path="/:lang?/products" element={<Products />} />
        <Route path="/:lang?/qurban/sheep" element={<SheepsPage />} />
        <Route path="/:lang?/qurban/cow" element={<CowsPage />} />
        <Route path="/:lang?/qurban-most-popular" element={<QurbanMostPopularPage />} />
        <Route path="/:lang?/sadaqah-most-popular" element={<SadaqahMostPopularPage />} />
        <Route path="/:lang?/zakat-calculator" element={<ZakatCalculatorUATLandingPage />} />
        <Route path="/:lang?/nisab" element={<Nisab />} />
        <Route path="/:lang?/zakat-landing" element={<ZakatLandingPage />} />
        <Route path="/:lang?/zakat" element={<ZakatUat />} />
        <Route path="/:lang?/zakat-on-gold" element={<ZakatOnGold />} />
        <Route path="/:lang?/zakat/zakat-al-fitr" element={<ZakatAlFitr />} />
        <Route path="/:lang?/success" element={<SuccessPage />} />
        <Route path="/:lang?/ali-banat" element={<AliBanatPage />} />
        <Route path="/:lang?/purpose" element={<PurposePage />} />
        <Route path="/:lang?/gdpr" element={<GDPR />} />
        <Route path="/:lang?/faq" element={<FAQsPage />} />
        <Route path="/:lang?/opt-out" element={<OptOutPage />} />
        <Route path="/:lang?/cancel" element={<CancelPage />} />
        <Route path="/:lang?/get-receipt" element={<GetReceiptPage />} />
        <Route path="/:lang?/social-media" element={<SocialMedia />} />
        <Route path="/:lang?/annual-reports" element={<AnnualReports />} />
        <Route path="/:lang?/policies" element={<PoliciesPage />} />
        <Route path="/:lang?/privacypolicy" element={<PrivacyPolicyPage />} />
        <Route path="/:lang?/volunteer" element={<NewVolunteerPage />} />
        <Route path="/:lang?/receipt" element={<ReceiptPage />} />
        <Route path="/:lang?/app" element={<AppPage />} />
        <Route path="/:lang?/global" element={<GlobalPage />} />
        <Route path="/:lang?/quick-donate" element={<QuickDonatePage />} />
        <Route path="/:lang?/food-and-water-aid" element={<FoodAndWaterLandingPage />} />
        {/* <Route path="/:lang?/food-and-water-aid-uat" element={<FoodAndWaterUATLandingPage />} /> */}
        <Route path="/:lang?/food-and-water-aid/water-aid" element={<WaterAidUATLandingPage />} />
        <Route path="/:lang?/food-and-water-aid/food-aid" element={<FoodAidUATLandingPage />} />
        <Route path="/:lang?/the-1-billion-legacy" element={<OneBillionLegacyPage />} />
        <Route path="/:lang?/vip" element={<VIPPage />} />
        <Route path="/:lang?/call-center" element={<CallCenter />} />
        <Route path="/:lang?/contact" element={<Contact />} />
        <Route path="/:lang?/error" element={<ErrorPage />} />
        <Route path="/:lang?/donate-to-palestine" exact element={<DonateToPalestine />} />
        <Route path="/:lang?/food-and-water-aid-fr" element={<FoodAndWaterLandingPage lang={'fr'} />} />
        <Route path="/:lang?/food-and-water-aid-ar" element={<FoodAndWaterLandingPage lang={'ar'} />} />
        <Route path="/:lang?/donate-to-gaza" exact element={<DonateToGaza />} />
        <Route path="/:lang?/donate-now/make-a-donation" element={<MakeADonationLandingPage />} />
        <Route path="/:lang?/loved-ones-legacy" element={<LoveOnesLandingPage />} />
        <Route path="/:lang?/winter" element={<WinterLandingPage />} />
        <Route path="/:lang?/live" element={<Live />} />
        <Route path="/:lang?/donor-receipts" element={<DonorReceipts />} />
        <Route path="/:lang?/test-subscription" element={<SubscriptionOld />} />
        <Route path="/:lang?/subscription" element={<SubscriptionLandingPage />} />
        <Route path="/:lang?/subscription-regular-giving" element={<SubscriptionABLandingPage />} />
        <Route path="/:lang?/ramadan" element={<RamadanLandingPage />} />
        <Route path="/:lang?/ramadan/laylatul-qadr" element={<LaylaTulQadrLandingPage />} />
        <Route path="/:lang?/orphans-fr" element={<OrphansUat lang={'fr'} />} />
        <Route path="/:lang?/orphans-ar" element={<OrphansUat lang={'ar'} />} />
        <Route path="/:lang?/test-widget" element={<TestWidget />} />

        <Route path="/:lang?/palestine-campaign-3-ar" element={<PalestineArabic />} />
        <Route path="/:lang?/ramadan/fidya-kaffarah" element={<FidyaKaffarah />} />
        <Route path="/:lang?/kaffarah" element={<KaffarahLandingPage />} />
        <Route path="/:lang?/fidya" element={<FidyaLandingPage />} />
        <Route path="/:lang?/uptime-status" element={<UpTimeStatus />} />
        <Route path="/:lang?/singapore" element={<USAPage country={'singapore'} />} />
        <Route path="/:lang?/our-promise" element={<OurPromise />} />
        <Route path="/:lang?/processing-crypto" element={<SentCryptoPage />} />
        <Route path="/:lang?/cancel-crypto" element={<CancelCryptoPage />} />
        <Route path="/:lang?/aqiqah" element={<Aqiqah />} />
        <Route path="/:lang?/our-promise" element={<OurPromise />} />
        <Route path="/:lang?/100-donation-policy" element={<HundredDonationsPolicy />} />
        <Route path="/:lang?/dua-for-breaking-fast" element={<DuaForBreakingFast />} />
        <Route path="/:lang?/difference-between-sadaqah-and-lillah" element={<SadaqahAndLillah />} />
        <Route path="/:lang?/dua-for-palestine" element={<DuaForPalestine />} />
        <Route path="/:lang?/dua-for-parents" element={<DuaForParents />} />
        <Route path="/:lang?/when-is-eid-2024" element={<WhenIsEid />} />
        <Route path="/:lang?/ramadan-dua" element={<RamadanDua />} />
        <Route path="/:lang?/gift-giving" element={<GiftGiving />} />
        <Route path="/:lang?/fitrana" element={<Fitrana />} />
        <Route path="/:lang?/news" element={<NewPage />} />
        <Route path="/:lang?/muharram" element={<MuharramLandingPage type={'main'} />} />
        <Route path="/:lang?/muharram-2024" element={<MuharramLandingPage type={'duplicated'} />} />
        <Route path="/:lang?/events/live-fundraiser" element={<LiveFundraiserPage />} />
        <Route path="/:lang?/events/morningtea" element={<MorningTeaPage />} />
        <Route exact path="/:lang?/events/yasminmogahed" element={<YasminmogahedLandingPage />} />
        <Route exact path="/:lang?/events/yasminmogahed-australia-feb" element={<YasminmogahedAustraliaPage />} />
        <Route exact path="/:lang?/events/qasimrafique-australia-feb" element={<YasminmogahedAustraliaPage />} />
        <Route exact path="/:lang?/events/alihammuda-au-tour" element={<EventLandingPage type="alihamud" />} />
        <Route exact path="/:lang?/events/iftardinner-march" element={<EventLandingPage type="iftar" />} />
        <Route exact path="/:lang?/events/belal-assaad-south-africa" element={<EventLandingPage type="belal" />} />
        <Route exact path="/:lang?/events/shaun-king-malaysia" element={<EventLandingPage type="shaun" />} />
        <Route exact path="/:lang?/events/abdul-wahab" element={<EventLandingPage type="abdulwahab" />} />
        <Route exact path="/:lang?/events/majedmahmoud-qurban" element={<MajedMahmoudLandingPage />} />
        <Route exact path="/:lang?/events/yasminmogahedindonesia" element={<YasminMogahedIndonesiaPage />} />
        <Route exact path="/:lang?/events/yasminmogahedmalaysia" element={<YasminMogahedMalaysiaPage />} />
        <Route exact path="/:lang?/events/yasminmogahedsingapore" element={<YasminMogahedSingaporePage />} />
        <Route exact path="/:lang?/events/asmaaandbelal" element={<AsmaaAndBelalPage />} />
        <Route exact path="/:lang?/events/yasirandnouman" element={<YasirandnoumanPage />} />
        <Route exact path="/:lang?/events/yasirandnoumanmalaysia" element={<YasirandnoumanMalaysiaPage />} />
        <Route exact path="/:lang?/events/noumanalikhan" element={<NoumanalikhanLandingPage />} />
        <Route exact path="/:lang?/events/muftimenk" element={<MuftiMenkLandingPage />} />
        <Route exact path="/:lang?/events/noumanalikhan/malaysia" element={<NoumanalikhanMalaysiaLandingPage />} />
        <Route exact path="/:lang?/events/belalassad-april2024" element={<BelalAssadApril />} />
        <Route exact path="/:lang?/events/alibanatlegacy" element={<AliBanatUSALandingPage />} />
        <Route exact path="/:lang?/events/alibanatlegacyca" element={<AliBanatCADLandingPage />} />
        <Route path="/:lang?/sadaqah-jariyah" element={<SadaqahjariahUat />} />
        <Route path="/:lang?/sadaqah" element={<Sadaqah />} />
        <Route path="/:lang?/sadaqah-jariyah-for-deceased" element={<SadaqahjariahForDeceased />} />
        <Route path="/:lang?/sadaqah-jariyah/project-noor" element={<ProjectNoorUAT />} />
        <Route path="/:lang?/sadaqah-jariyah/orphan-homes" element={<OrphanHomesLandingPage />} />
        <Route path="/:lang?/sadaqah-jariyah/build-a-water-well" element={<BuildWaterWellUat />} />
        <Route path="/:lang?/olive-tree-sadaqah" element={<OliveTreeSadaqah />} />
        <Route path="/:lang?/sadaqah-jariyah/sustainable" element={<SustainableLandingPage />} />
        <Route path="/:lang?/sadaqah-jariyah/orphans" element={<OrphanagesLandingPage />} />
        <Route path="/:lang?/sadaqah-jariyah/build-a-masjid" element={<MasjidsNewLandingPage type="buildAMasjid" />} />
        <Route path="/:lang?/donation-for-masjid" element={<MasjidsNewLandingPage type="donationForMasjid" />} />
        <Route path="/:lang?/sadaqah-jariyah/emergency" element={<EmergencyLandingPage />} />
        <Route path="/:lang?/sadaqah-jariyah/health" element={<HealthLandingPage />} />
        <Route path="/:lang?/orphans" element={<OrphansUat />} />
        <Route path="/:lang?/orphans-fr" element={<OrphansUat lang={'fr'} />} />
        <Route path="/:lang?/orphans-ar" element={<OrphansUat lang={'ar'} />} />
        <Route path="/:lang?/orphans/orphan-feast" element={<OrphansFeastUat />} />
        <Route path="/:lang?/orphans/education" element={<OrphanEducationLandingPage />} />
        <Route path="/:lang?/orphans/support-an-orphan" element={<OrphanSupportLandingPage />} />
        <Route path="/:lang?/sponsor-an-orphan" element={<SponsorAnOrphan />} />
        <Route path="/:lang?/orphans/orphan-widget" element={<ZakatWidgetEmbedded />} />
        {/* <Route path="/:lang?/crisis-and-emergencies/afghanistan" exact element={<AfghanistanLandingPage />} /> */}
        <Route path="/:lang?/crisis-and-emergencies/palestine" exact element={<PalestineLandingPage />} />
        <Route path="/:lang?/crisis-and-emergencies/palestine-famine" exact element={<PalestineFamineLandingPage lang={'en'} />} />
        <Route path="/:lang?/palestine-famine-ar" exact element={<PalestineFamineLandingPage lang={'ar'} />} />
        <Route path="/:lang?/palestine-famine-fr" exact element={<PalestineFamineLandingPage lang={'fr'} />} />
        <Route path="/:lang?/crisis-and-emergencies/palestine-subscription" exact element={<SubscriptionLandingPage />} />
        <Route path="/:lang?/crisis-and-emergencies/palestine-campaign" exact element={<PalestineCampaignLandingPage />} />
        <Route path="/:lang?/crisis-and-emergencies/palestine-response" exact element={<PalestineResponseLandingPage />} />
        <Route path="/:lang?/crisis-and-emergencies/emergency-response" exact element={<EmergencyResponseLandingPage />} />
        <Route path="/:lang?/crisis-and-emergencies/morocco" exact element={<MoroccoEmergencyLandingPage />} />
        {/* <Route path="/:lang?/crisis-and-emergencies/libya-floods" exact element={<LibyaEmergencyLandingPage />} /> */}
        <Route path="/:lang?/crisis-and-emergencies/libya-floods" exact element={<Navigate to="/crisis-and-emergencies" replace />} />
        <Route path="/:lang?/crisis-and-emergencies/earthquake-appeal" exact element={<EarthquakeAppealPage />} />
        <Route path="/:lang?/crisis-and-emergencies/palestine-weekly-au" exact element={<PalestineWeekly country={'au'} />} />
        <Route path="/:lang?/crisis-and-emergencies/palestine-weekly-usa" exact element={<PalestineWeekly country={'usa'} />} />
        <Route path="/:lang?/crisis-and-emergencies/palestine-weekly-uk" exact element={<PalestineWeekly country={'uk'} />} />
        <Route path="/:lang?/crisis-and-emergencies/palestine-weekly-global" exact element={<PalestineWeekly country={'global'} />} />
        <Route path="/:lang?/crisis-and-emergencies/save-orphans" exact element={<SaveOrphansLandingPage lang={'en'} />} />
        <Route
          path="/:lang?/crisis-and-emergencies/palestine-refugee-settlement"
          element={<PalestineRefugeeLandingPage lang={'en'} type="RefusseP" />}
        />
        <Route path="/:lang?/crisis-and-emergencies/bangladesh-appeal" element={<BangladeshAppeal />} />
        <Route path="/:lang?/crisis-and-emergencies/countries-in-crisis" element={<CountriesInCrisisLandingPage />} />
        <Route path="/:lang?/medical-aid-for-palestinians" element={<PalastineMedicalRelief />} />
        <Route path="/:lang?/palestinian-refugees-egypt" element={<PalestineRefugeeLandingPage lang={'en'} />} />
        <Route path="/:lang?/palestine-refugee-settlement-ar" element={<PalestineRefugeeLandingPage lang={'ar'} />} />
        <Route path="/:lang?/palestine-refugee-settlement-fr" element={<PalestineRefugeeLandingPage lang={'fr'} />} />
        <Route path="/:lang?/save-palestine-orphans-ar" exact element={<SaveOrphansLandingPage lang={'ar'} />} />
        <Route path="/:lang?/save-palestine-orphans-fr" exact element={<SaveOrphansLandingPage lang={'fr'} />} />
        <Route path="/:lang?/palestine-fr" exact element={<PalestineLandingPage lang={'fr'} />} />
        {/* <Route path="/:lang?/palestine-ar-" exact element={<PalestineLandingPage lang={'ar'} />} /> */}
        <Route path="/:lang?/palestine-ar" exact element={<PalestineArabicLandingPage />} />
        <Route path="/:lang?/dashboard" element={<DashboardPage />} />
        <Route path="/:lang?/donor" element={<DonorPage />} />
        <Route path="/:lang?/dashboard/*" element={<DashboardPage />} />
        <Route path="/:lang?/donor/*" element={<DonorPage />} />
        <Route path="/:lang?/undefined/*" element={<HomePageUAT />} />
        <Route path="/:lang?/events/:id" element={<EventDetails />} />
        <Route path="/:lang?/appeals/:id" element={<DetailsProduct />} />
        <Route path="/:lang?/appeals/palestine/:id" element={<DetailsPalestineProduct />} />
        <Route path="/:lang?/:name" element={<SubCategory />} />
        <Route path="/:lang?/:name/:product" element={<Products />} />
        <Route path="/:lang?/ramadan/fidya-kaffarah-uat" element={<FidyaKaffarahUat />} />
        <Route path="/:lang?/ramadan/last-10-nights" element={<LaylaTulQadrLandingPage />} />
        <Route path="/:lang?/laylatul-qadr-2024" element={<LaylatulQadr2024LandingPage />} />
        <Route path="/:lang?/volunteer-uat" element={<VolunteerUATPage />} />
        <Route path="/:lang?/sadaqah-jariyah-uat" element={<SadaqahjariahUat />} />
        <Route path="/:lang?/sadaqah-jariyah/build-a-water-well-uat" element={<BuildWaterWellUat />} />
        <Route path="/:lang?/sadaqah-jariyah/gift-of-sound" element={<GiftOfSoundLandingPage />} />
        <Route path="/:lang?/campaign-tt" element={<CampaignTiktok />} />
        <Route path="/:lang?/phishing-scams" element={<PhishingLandingPage />} />
        <Route path="/:lang?/admin-fee" element={<AdminFeesPage />} />
        <Route path="/:lang?/live-meals" element={<LiveMeals />} />
        <Route path="/:lang?/eid-al-fitr-2024" element={<EidAlFitr2024 />} />
        <Route path="/:lang?/eid-mubarak-2024" element={<EidMubarak2024 />} />
        <Route path="/:lang?/top-appeals" element={<TopAppeals />} />

        <Route path="/:lang?/qurbani-2024" element={<Qurbani2024LandingPage />} />
        <Route path="/:lang?/qurbani-2023" element={<Navigate to="/qurbani-2024" replace />} />
        <Route path="/:lang?/qurbani-palestine" element={<Qurbani2024LandingPage country={'Palestine'} />} />
        <Route path="/:lang?/qurban" element={<QurbanMainLandingPage lang={'en'} title={'Qurban'} />} />
        <Route path="/:lang?/qurban-ar" element={<QurbanMainLandingPage lang={'ar'} />} />
        <Route path="/:lang?/qurban-fr" element={<QurbanMainLandingPage lang={'fr'} />} />
        <Route path="/:lang?/cheapest-qurbani-2024" element={<CheapestQurbani2024LandingPage />} />
        <Route path="/:lang?/qurbani" element={<QurbanMainLandingPage lang={'en'} title={'Qurbani'} />} />
        <Route path="/:lang?/qurban/palestine" element={<QurbanPalestineLandingPage title="Qurban" lang={'en'} />} />
        <Route path="/:lang?/qurbani/palestine" element={<QurbanPalestineLandingPage title="Qurbani" />} />
        <Route path="/:lang?/qurban/palestine-fr" element={<QurbanPalestineLandingPage title="Qurban" lang={'fr'} />} />
        <Route path="/:lang?/qurban/palestine-ar" element={<QurbanPalestineLandingPage title="Qurban" lang={'ar'} />} />
        <Route path="/:lang?/qurbani-donation" element={<QurbaniDonationLandingPage />} />
        <Route path="/:lang?/qurbani-yemen" element={<Qurbani2024LandingPage country={'Yemen'} />} />
        <Route path="/:lang?/qurbani-egypt" element={<Qurbani2024LandingPage country={'Egypt'} />} />
        <Route path="/:lang?/qurbani-africa" element={<Qurbani2024LandingPage country={'Africa'} />} />
        <Route path="/:lang?/qurbani-syria" element={<Navigate to="/qurbani" replace />} />
        <Route path="/:lang?/qurbani-afghanistan" element={<Qurbani2024LandingPage country={'Afghanistan'} />} />
        <Route path="/:lang?/qurbani-jordan" element={<Qurbani2024LandingPage country={'Jordan'} />} />
        <Route path="/:lang?/qurbani-lebanon" element={<Qurbani2024LandingPage country={'Lebanon'} />} />
        <Route path="/:lang?/qurbani-bangladesh" element={<Qurbani2024LandingPage country={'Bangladesh'} />} />
        <Route path="/:lang?/eid-qurban-2024" element={<Qurbani2024LandingPage title={'Qurban'} />} />
        <Route path="/:lang?/qurban/dhul-hijjah" element={<QurbanDhulHijjahLandingPage />} />
        <Route path="/:lang?/qurban/ultimate-qurbani" element={<UltimateQurbanLandingPage title="Qurbani" />} />
        <Route path="/:lang?/qurban/ultimate-qurban" element={<UltimateQurbanLandingPage title="Qurban" lang={'en'} />} />
        <Route path="/:lang?/qurban/ultimate-qurban-ar" element={<UltimateQurbanLandingPage title="Qurban" lang={'ar'} />} />
        <Route path="/:lang?/qurban/ultimate-qurban-fr" element={<UltimateQurbanLandingPage title="Qurban" lang={'fr'} />} />
        <Route path="/:lang?/qurban/day-of-arafat" element={<QurbanDayOfArafatLandingPage title="Qurbani" />} />
        <Route path="/:lang?/qurban-campaign-1" element={<QurbanMainLandingPage isCampaign title={'Qurban'} />} />
        <Route path="/:lang?/qurbani-campaign-1" element={<QurbaniLandingPage isCampaign />} />
        <Route path="/:lang?/kurban-bayrami-2024" element={<KurbanBayramiLandingPage />} />
        <Route path="/:lang?/eid-al-adha-2024" element={<EidAlAdha2024LandingPage />} />
        <Route path="/:lang?/dhul-hijjah-2024" element={<DhulHijjah2024LandingPage />} />
        <Route path="/:lang?/pakistan-qurbani" element={<Qurbani2024LandingPage country={'Pakistan'} />} />
        <Route path="/:lang?/qurban/eid-al-adha" element={<QurbanEidAlAdhaLandingPage title="Qurban" />} />
        <Route path="/:lang?/crisis-and-emergencies/afghanistan" element={<AfghanistanLandingPageUAT />} />
        <Route path="/:lang?/widget" element={<WidgetEmbedded />} />
        <Route path="/:lang?/palestine-aia-partnership" element={<PalestineAiaPartnershipLandingPage />} />
        <Route path="/:lang?/orphans-aia-sponsorship" element={<OrphansAiaSponsorshipLandingPage />} />
        <Route path="/:lang?/doublethedonation" element={<DoubleTheDonationPage />} />
        <Route path="/:lang?/top-10" element={<Top10Products page="general" />} />
        <Route path="/:lang?/top-10-palestine" element={<Top10Products page="palestine" />} />
        <Route path="/:lang?/appeal/:id" element={<AppealLandingPage />} />
        <Route path="/:lang?/appeals/palestine-emergency" element={<MATWAppealDetailsLandingPage campaign="palestine" />} />
        <Route path="/:lang?/appeals/palestine-refugee-resettlement" element={<MATWAppealDetailsLandingPage campaign="egyptian" />} />
        <Route path="/:lang?/appeals/limbs-of-hope" element={<MATWAppealDetailsLandingPage campaign="limbs-of-hope" />} />
        <Route path="/:lang?/appeals/appeal/limbs-of-hope" element={<MATWAppealLandingPage campaign="limbs-of-hope" />} />
        <Route path="/:lang?/appeals/appeal/palestine-emergency" element={<MATWAppealLandingPage campaign="palestine" />} />
        <Route path="/:lang?/appeals/appeal/palestine-refugee-resettlement" element={<MATWAppealLandingPage campaign="egyptian" />} />
        <Route path="/:lang?/children-of-gaza" element={<ChildrenOfGaza />} />
        <Route path="/:lang?/limbs-of-hope" element={<LimbsOfHopeLandingPage />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <ScrollToTop />
    </RouteContext.Provider>
  );
};

export default AllRoutes;
